<template>
  <div class="grid p-fluid">
    <div class="col-12">
      <Toast />
      <div
        class="field-checkbox mt-3"
        v-if="autoAccept.autoAcceptManualBooking !== null"
      >
        <Checkbox
          id="auto-manual"
          :binary="true"
          v-model="autoAccept.autoAcceptManualBooking"
        />
        <label for="auto-manual">
          Aceptar automáticamente reservas del punto de venta
        </label>
      </div>
      <div v-else>
        <Skeleton class="mb-1" width="16rem" height="2rem"></Skeleton>
      </div>
      <div
        class="field-checkbox"
        v-if="autoAccept.autoAcceptAutomaticBooking !== null"
      >
        <Checkbox
          id="auto-automatic"
          :binary="true"
          v-model="autoAccept.autoAcceptAutomaticBooking"
        />
        <label for="auto-automatic">
          Aceptar automáticamente reservas de clientes
        </label>
      </div>
      <div class="field-checkbox" v-if="autoAccept.rejectDuplicates !== null">
        <Checkbox
          id="deny-duplicates"
          :binary="true"
          v-model="autoAccept.rejectDuplicates"
        />
        <label for="deny-duplicates">
          No aceptar múltiples reservas del mismo cliente para el mismo día y
          hora
        </label>
      </div>
      <div v-else>
        <Skeleton width="16rem" height="2rem"></Skeleton>
      </div>
      <div
        class="p-float-label mt-6 lg:col-4"
        v-if="bookingAdvanceHours !== undefined"
      >
        <InputNumber
          v-model="bookingAdvanceHours"
          mode="decimal"
          id="advance_hours"
          :minFractionDigits="0"
          :min="1"
          :max="168"
          required
          @blur="updateBookingAdvanceHours"
        />
        <label for="advance_hours" style="font-size: 1em;">
          Mínimo de horas de antelación de la reserva
        </label>
      </div>
      <div v-else>
        <Skeleton width="16rem" height="2rem"></Skeleton>
      </div>
      <div
        class="p-float-label mt-6 lg:col-4"
        v-if="bookingCancelHours !== undefined"
      >
        <InputNumber
          v-model="bookingCancelHours"
          mode="decimal"
          id="cancel_hours"
          :minFractionDigits="0"
          :min="1"
          :max="168"
          required
          @blur="updateBookingCancelHours"
        />
        <label for="cancel_hours" style="font-size: 1em;">
          Mínimo de horas de cancelación de la reserva
        </label>
      </div>
      <div v-else>
        <Skeleton width="16rem" height="2rem"></Skeleton>
      </div>

      <h4 class="title">Campos personalizados</h4>
      <template v-if="formFields !== null">
        <template v-for="formField in formFields" :key="formField.id">
          <custom-form-field
            :fieldData="formField"
            :isEdit="idEdit.includes(formField.id)"
            @toggle="toggleEditField(formField.id)"
            @remove="removeField(formField.id)"
            @save="saveEdit(formField.id, $event)"
          />
        </template>
        <Button
          icon="pi pi-plus"
          @click="onAddFieldClick"
          label="Añadir campo personalizado"
          class="w-auto"
        />
        <Dialog
          v-model:visible="addFieldModal"
          header="Nuevo campo personalizado"
          :modal="true"
          class="p-fluid"
        >
          <custom-form-field
            @toggle="hideAddFieldModal()"
            @save="createField($event)"
            :customFieldNames="fieldNames"
          />
        </Dialog>
      </template>
      <ProgressSpinner v-else />
    </div>
  </div>
</template>

<script>
import { CUSTOM_FORM_FIELD_TYPES } from '../../constants/constants';
import OrganizationService from '../../service/OrganizationService';

export default {
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    this.organizationService
      .getConfig(this.$route.params.id)
      .then((config) => this.parseConfig(config));
  },
  computed: {
    fieldNames() {
      return this.formFields.map((field) => field.name);
    },
  },
  data() {
    return {
      organizationService: null,
      idEdit: [],
      autoAccept: {
        autoAcceptManualBooking: null,
        autoAcceptAutomaticBooking: null,
        rejectDuplicates: null,
      },
      bookingAdvanceHours: undefined,
      bookingCancelHours: undefined,
      formFields: null,
      addFieldModal: false,
      maxId: 0,
    };
  },
  watch: {
    autoAccept: {
      handler(newAutoAccept, oldAutoAccept) {
        console.log(newAutoAccept);
        if (
          oldAutoAccept !== undefined &&
          oldAutoAccept.autoAcceptManualBooking !== null &&
          oldAutoAccept.autoAcceptAutomaticBooking !== null &&
          oldAutoAccept.rejectDuplicates !== null
        )
          this.updateAutoAccept(newAutoAccept);
      },
      deep: true,
    },
  },
  methods: {
    updateBookingAdvanceHours() {
      setTimeout(async () => {
        if (this.bookingAdvanceHours === null) return;
        await this.organizationService.putAdvanceTime(this.$route.params.id, {
          hours: this.bookingAdvanceHours,
        });
        this.$toast.add({
          severity: 'success',
          summary: 'Tiempo mínimo de antelación de reserva actualizado',
          life: 3000,
        });
      }, 5);
    },
    updateBookingCancelHours() {
      setTimeout(async () => {
        if (this.bookingCancelHours === null) return;
        await this.organizationService.putCancelTime(this.$route.params.id, {
          hours: this.bookingCancelHours,
        });
        this.$toast.add({
          severity: 'success',
          summary: 'Tiempo mínimo de cancelación de reserva actualizado',
          life: 3000,
        });
      }, 5);
    },
    async updateAutoAccept(newAutoAccept) {
      await this.organizationService.putAutoaccept(
        this.$route.params.id,
        newAutoAccept
      );
      this.$toast.add({
        severity: 'success',
        summary: 'Actualizada configuración de reservas automáticas',
        life: 3000,
      });
    },
    onAddFieldClick() {
      this.addFieldModal = true;
    },
    hideAddFieldModal() {
      this.addFieldModal = false;
    },
    async createField(newField) {
      const fieldWithId = {
        ...newField,
        '@type': this.getFieldType(newField.type),
        id: this.generateId(),
      };
      const index = this.formFields.push(fieldWithId) - 1;
      this.hideAddFieldModal();
      try {
        await this.organizationService.createFormField(
          this.$route.params.id,
          fieldWithId
        );
        this.$toast.add({
          severity: 'success',
          summary: 'Campo creado',
          detail: `Se ha completado la creación de ${newField.name}`,
          life: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error creando campo',
          detail: `Ha habido un error creando ${newField.name}, inténtelo más tarde`,
          life: 3000,
        });
        this.formFields.splice(index, 1);
      }
    },
    toggleEditField(fieldId) {
      if (this.idEdit.includes(fieldId)) {
        this.idEdit.splice(this.idEdit.indexOf(fieldId), 1);
      } else {
        this.idEdit.push(fieldId);
      }
    },
    async removeField(fieldId) {
      const index = this.formFields.findIndex((field) => field.id === fieldId);
      const field = this.formFields.splice(index, 1)[0];
      const fieldName = field.name;
      try {
        await this.organizationService.deleteFormField(
          this.$route.params.id,
          fieldName
        );
        this.$toast.add({
          severity: 'success',
          summary: 'Campo eliminado',
          detail: `Se ha eliminado el campo ${fieldName}`,
          life: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error eliminando campo',
          detail: `Ha habido un error eliminando ${fieldName}, inténtelo más tarde`,
          life: 3000,
        });
        this.formFields.splice(index, 0, field);
      }
    },
    async saveEdit(fieldId, newField) {
      newField['@type'] = this.getFieldType(newField.type);
      const originalFormField = this.formFields.splice(
        this.formFields.findIndex((field) => field.id === fieldId),
        1,
        newField
      )[0];
      this.toggleEditField(fieldId);
      try {
        await this.organizationService.updateFormField(
          this.$route.params.id,
          originalFormField.name,
          newField
        );
        this.$toast.add({
          severity: 'success',
          summary: 'Campo guardado',
          detail: `Se ha guardado el campo ${newField.name}`,
          life: 3000,
        });
      } catch (error) {
        console.log(error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error guardando el campo',
          detail: `Ha habido un error guardando ${newField.name}, inténtelo más tarde`,
          life: 3000,
        });
        this.toggleEditField(fieldId);
      }
    },
    parseConfig(config) {
      const formFields = config.bookingConfig.bookingFormConfig.formFields.map(
        (formField) => ({ ...formField, id: this.generateId() })
      );
      this.formFields = formFields;
      const newAutoAccept = {
        autoAcceptManualBooking: config.bookingConfig.autoAcceptManualBooking,
        autoAcceptAutomaticBooking:
          config.bookingConfig.autoAcceptAutomaticBooking,
        rejectDuplicates: config.bookingConfig.rejectDuplicates,
      };
      this.autoAccept = newAutoAccept;
      this.bookingAdvanceHours = config.bookingConfig.bookingAdvanceHours;
      this.bookingCancelHours = config.bookingConfig.bookingCancelHours;
    },
    addCustomField(customFieldData) {
      const newField = {
        ...customFieldData,
        '@type': this.getFieldType(customFieldData.type),
      };
      this.formFields.push(newField);
      console.log(this.formFields);
    },
    getFieldType(type) {
      return CUSTOM_FORM_FIELD_TYPES[type] ?? '';
    },
    generateId() {
      this.maxId++;
      return this.maxId;
    },
  },
};
</script>

<style>
.title {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
</style>
